<template>
  <div class="about container">
    <div v-if="lang == 'french'">
    <h2 class="title" style="color:white;">À propos</h2>

    <p>Vous venez de découvrir l’église de Folleville et ses trésors. Cela a pu être possible grâce à une poignée de passionnés qui se sont regroupés dans l’association « Folleville : une Église, une Histoire… ». Elle se charge d’en assurer la Promotion et la Valorisation.</p>
    <p>Elle est joignable par mail à <a style="color:black;background:white;border-radius:4px;padding:4px;" mailto="eglisedefolleville@orange.fr">eglisedefolleville@orange.fr</a> et il est possible d’y adhérer à tout instant via HelloAsso</p>
    <p><a style="color:black;background:white;border-radius:4px;padding:4px;" href="https://frama.link/Adhesion2020">https://frama.link/Adhesion2020</a></p>
    <br/><br/>
    <img src="/qr_adherer_association.png" />
    <hr/>
    <p>L'application a été réalisée par <a style="color:black;background:white;border-radius:4px;padding:4px;" href="https://www.ideesculture.com"><b>IdéesCulture</b></a>.<br/>
    Sa refonte, en 2021, a permis l'ajout de la langue anglaise ainsi qu'un nouveau travail graphique, une ergonomie améliorée et un quizz.<br/>
    Le développement a été réalisé par <b>Gautier Michelin</b>, les textes sont issus de documents de <b>Pierre Michelin</b>.
    <br/>
    <b>Florent Cuendet</b> a travaillé sur les contenus, l'iconographie et cette refonte dans le cadre de son stage de fin d'études de  (master pro M2 Patrimoine et développement local, Université du Mans).
    Il a également complété les traductions en anglais.
    </p>
    <hr>
    <i class="fas fa-camera"></i> Merci à :<br/>
    - Patrick Monchicourt <a style="color:white;" href=https://www.flickr.com/photos/morio60>https://www.flickr.com/photos/morio60</a><br/>
    - Gérard Michel <a style="color:white;" href=https://www.flickr.com/photos/gerard_michel>https://www.flickr.com/photos/gerard_michel</a>
    <p style="height:40px;"></p>
    <hr/>
    L'association est engagée dans un projet de réalisation de vitraux pour remplir les grandes verrières blanches, projet appelé notamment de ses voeux par Pierre Michelin <i class="fas fa-cross"></i>.<br/>
    Vous avez aimé cette application ?<br/>
     Soutenez ce projet en donnant à l'association.
    <p style="height:220px;"></p>
    </div>
    <div v-else>
      <h2 class="title" style="color:white;">À propos</h2>

      <p>You have just discovered the church of Folleville and its treasures. This was made possible by a handful of enthusiasts who came together in the association « Folleville : une Église, une Histoire… ». It is responsible for the promotion and enhancement of the place.</p>
      <p>It can be contacted by email at <a style="color:black;background:white;border-radius:4px;padding:4px;" mailto="eglisedefolleville@orange.fr">eglisedefolleville@orange.fr</a> and it is possible to join at any time via HelloAsso</p>
      <p><a style="color:black;background:white;border-radius:4px;padding:4px;" href="https://frama.link/Adhesion2020">https://frama.link/Adhesion2020</a></p>
      <br/><br/>
      <img src="/qr_adherer_association.png" />
      <hr/>
      <p><a style="color:black;background:white;border-radius:4px;padding:4px;" href="https://www.ideesculture.com"><b>IdéesCulture</b></a> produced this app.<br/>
      Its redesign, in 2021, added the English language as well as new graphics, an improved ergonomics and a quiz.<br/>
      <b>Gautier Michelin</b> did the development, the texts were taken from <b>Pierre Michelin</b> documents.
      <br/>
      <b>Florent Cuendet</b> worked on the contents, iconography and this redesign as part of his end-of-study internship (Master pro M2 Patrimoine et développement local, Université du Mans). He also worked on the English translations. 
      </p>
      <hr>
      <i class="fas fa-camera"></i> Thanks to :<br/>
      - Patrick Monchicourt <a style="color:white;" href=https://www.flickr.com/photos/morio60>https://www.flickr.com/photos/morio60</a><br/>
      - Gérard Michel <a style="color:white;" href=https://www.flickr.com/photos/gerard_michel>https://www.flickr.com/photos/gerard_michel</a>
      <p style="height:40px;"></p>
      <hr/>
      L'association est engagée dans un projet de réalisation de vitraux pour remplir les grandes verrières blanches, projet appelé notamment de ses voeux par Pierre Michelin <i class="fas fa-cross"></i>.<br/>
      Vous avez aimé cette application ?<br/>
      Soutenez ce projet en donnant à l'association.
      <p style="height:220px;"></p>      
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  components: {
  },
  data() {
    return {
    };
  },
  created() {},
  computed: {
    lang: function() {
      return this.$parent.$parent.lang;
    }

  }
};
</script>

<style scoped>
.about {
  padding:4px 20px;
  color:white !important;
}

</style>
